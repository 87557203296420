import React from "react"
import styled from "styled-components"

import { media } from "../../variables"
import { P, H1, H2  } from "../BaseElements/BaseElements"
import Button from "../../components/Button/Button"
import LogoImage from "../../images/tree-and-ravine-logo-small.png"

const Hero = () => (
  <StyledHero>
    <ContentContainer>
      <Content>
        <img
          src={LogoImage}
          width="100"
          height="100"
          alt="Tree and Ravine Logo"
        />
        <H1>
          Tree and Ravine Inc.
          <br />
          Certified Arborist Services
        </H1>
        <H2 style={{color: '#ffffff'}}>
          Important Announcement
        </H2>
        <ContactBlock>
          <P>I am pleased to announce a partnership between Tree & Ravine Inc. and <a style={{color: '#ffffff'}} href="https://www.cmtrees.com/" target="_blank" rel="noopener noreferrer">Cohen & Master Tree and Shrub
          Services Ltd.</a> and that moving forward, I will be joining the team at Cohen & Master Tree and Shrub
          Services and working with them in order to better serve our clients.</P>

          <P>Working with Cohen & Master will allow me to expand my toolbox, as I can now offer a wider variety of
          services relating to consulting, including Sonic Tomography. In addition, Cohen & Master can provide a
          full range of in-house services including tree removals, tree pruning, planting and plant health care.
          My promises to you are a seamless transition - any ongoing projects will continue as usual - and the same
          quality of work and service as you’ve come to expect from Tree & Ravine.</P>

          <P>Cohen & Master is staffed by a team of courteous, friendly, competent professionals, backed by the
          latest in equipment and technology, with the ultimate objective of optimizing the benefits of the
          science-based services they deliver. I look forward to this new opportunity and the amazing work we
          can do together.</P>

          <P>Thank you to everyone that helped make Tree & Ravine a success and assisted us in achieving our goals
          for the company. We look forward to this chapter of the journey and we are excited to continue to
          provide top level quality services to you in the future.</P>

          <P>Please do not hesitate to contact us if you have any questions about this transition.</P>

          <P>
            Sincerely<br />
            Sebastian,<br />
            Tree & Ravine Inc.
          </P>
        </ContactBlock>
        <Button href="/#contact">Contact Us</Button>
        <Button href="https://www.cmtrees.com/" target="_blank" rel="noopener noreferrer">
          Visit Cohen & Master
        </Button>
      </Content>
    </ContentContainer>
    <PhotoCredit>
      <span>Photo by</span>{" "}
      <a
        rel="nofollow noopener noreferrer"
        target="_blank"
        href="https://unsplash.com/@davidvig?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
      >
        David Vig
      </a>{" "}
      <span>on</span>{" "}
      <a
        rel="nofollow noopener noreferrer"
        target="_blank"
        href="/s/photos/trees?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
      >
        Unsplash
      </a>
    </PhotoCredit>
  </StyledHero>
)

const StyledHero = styled.section`
  /* min-height: 100vh; */
  min-height: 575px;
  position: relative;
`

const ContentContainer = styled.div`
  /* height: 100vh; */
  padding: 75px 0;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  min-height: 575px;
  background: rgba(0, 0, 0, 0.5);

  img {
    display: none;
    max-width: 150px;
    max-height: 150px;

    ${media.xsm`
      display: inline-block;
    `}

    ${media.sm`
      max-width: 200px;
      max-height: 200px;
    `}
  }
`

const Content = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;

`

const ContactBlock = styled.div`
  padding: 20px 16px;
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
  font-size: 1.5em;
`

// const StyledH3 = styled(H3)`
//   font-size: 20px;
//   font-weight: normal;
//   margin-bottom: 10px;

//   strong {
//     font-size: 25px;
//     padding: 0 4px;
//   }
// `

const PhotoCredit = styled.span`
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #fff;
  font-size: 10px;

  span {
    opacity: 0.5;
    transition: opacity 0.3s ease-out;
  }

  a {
    opacity: 0.5;
    transition: opacity 0.3s ease-out;
    color: #fff;

    &:hover {
      opacity: 1;
    }
  }
`

export default Hero
