import React, { useState } from "react"
import styled from "styled-components"
import Loadable from "@loadable/component"

import LazyLoadContainer from "../LazyLoadContainer/LazyLoadContainer"
import { Container, H2, P } from "../BaseElements/BaseElements"
import Loader from "../Loader/Loader"

const Contact = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  const FormContact = Loadable(() => import("./FormContact"), {
    fallback: (
      <form name="contact" netlify-honeypot="bot-field" data-netlify="true">
        <div hidden>
          <input name="firstname" />
          <input name="lastname" />
          <input name="email" />
          <input name="message" />
          <input name="bot-field" />
        </div>
        <div>
          <center>
            <Loader />
          </center>
        </div>
      </form>
    ),
  })

  return (
    <ContactSection id="contact" name="contact">
      <Container>
        <H2>Contact Us</H2>
        <P>
          If you would like to learn more about our services or have an inquiry
          please fill in the form below.
        </P>
      </Container>
      <LazyLoadContainer onLoad={() => setIsLoaded(true)} />
      {!isLoaded && (
        <form name="contact" netlify-honeypot="bot-field" data-netlify="true">
          <div hidden>
            <input name="firstname" />
            <input name="lastname" />
            <input name="email" />
            <input name="message" />
            <input name="bot-field" />
          </div>
          <div>
            <center>
              <Loader />
            </center>
          </div>
        </form>
      )}
      {isLoaded && <FormContact />}
    </ContactSection>
  )
}

const ContactSection = styled.section`
  background: rgba(255, 255, 255, 0.95);
  padding: 40px 0;
  z-index: 3;

  > ${Container} {
    text-align: center;
  }
`

export default Contact
