import React, { useEffect, useState } from "react"
import styled from "styled-components"

import Seo from "../components/seo"
import Layout from "../components/Layout/Layout"
import Hero from "../components/Hero/Hero"
import Contact from "../components/Contact/Contact"
import background from "../images/background.jpg"
import backgroundMobile from "../images/background-mobile.jpg"

const Home = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [fadeIn, setFadeIn] = useState(false)

  useEffect(() => {
    setIsLoaded(true)
    setTimeout(() => setFadeIn(true), 200)
  }, [])

  return (
    <Layout toggleHeader={true}>
      <Seo
        noTemplate
        title="Tree and Ravine | Arborists serving Toronto and the GTA"
      />
      <StyledHome>
        <Hero />
        <Contact />
      </StyledHome>
      {isLoaded && <TreeBg fadeIn={fadeIn} />}
    </Layout>
  )
}

const StyledHome = styled.div`
  position: relative;
  z-index: 2;
`

const TreeBg = styled.div`
  top: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 1;
  opacity: ${({ fadeIn }) => (fadeIn ? 1 : 0)};
  transition: opacity 0.3s ease-out;

  @media (max-width: 600px) {
    background: url(${backgroundMobile});
    background-size: cover;
    background-position: center;
  }

  @media (min-width: 601px) {
    background: url(${background});
    background-size: cover;
    background-position: center;
  }
`

export default Home
